import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry },
  } = useRuntimeConfig()

  if (!sentry.dsn) {
    console.warn('Sentry DSN is missing, skipping Sentry initialization')
    return
  }

  console.log('Initializing Sentry', sentry)

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    release: sentry.release,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', 'https://avjevnnings.localdev.me'],

    tracesSampleRate: parseFloat(sentry.tracesSampleRate || '0.1'),
    replaysSessionSampleRate: parseFloat(sentry.replaySampleRate || '0.1'),
    replaysOnErrorSampleRate: parseFloat(sentry.errorReplaySampleRate || '1'),
  })
})
